
          @charset "utf-8";
          @import "~bulma/sass/utilities/initial-variables.sass";
          @import "~bulma/sass/utilities/functions.sass";
          @import "~bulma/sass/utilities/derived-variables.sass";
          @import "~bulma/sass/utilities/mixins.sass";

          @import "~@/scss/_theme-default.scss";
          @import "~@/scss/_variables.scss";
          @import "~@/scss/_mixins.scss";
        

.router-tabs {
    width: 100%;
    
    ::v-deep {
        .el-tabs__item {
            color: #9AA3B4;
            
            &.is-active {
                color: #162239;
            }
        }

        .el-tabs__active-bar {
            background-color: #FE2615;
        }

        .el-tabs__nav-wrap {
            padding: 24px;
            &:after {
                height: 1px;
            }
        }
    }
}
