@import "~bulma/sass/utilities/functions";
@import "~bulma/sass/utilities/initial-variables";

$alpha: 0.85;

//$primary: rgb(255, 180, 33);
$primary: rgb(255, 186, 64);
$primary-invert: white;
//noinspection CssInvalidFunction
//$primary-invert: findColorInvert($primary);

$purple: #5921A0;

$primary-light: transparentize($primary, $alpha);
$primary-light-invert: $primary;

$light: rgb(181, 181, 181);
//$light: rgb(213, 213, 213);
//noinspection CssInvalidFunction
$light-invert: findColorInvert($light);

//noinspection SpellCheckingInspection
$light-light: transparentize($light, $alpha);
$light-light-invert: rgb(130, 130, 130);

//$info: rgb(65, 160, 218);
$info: rgb(26, 92, 255);
//noinspection CssInvalidFunction
$info-invert: findColorInvert($info);

//noinspection SpellCheckingInspection
$info-light: transparentize($info, $alpha);
$info-light-invert: $info;

//$success: rgb(115, 214, 143);
//$success: rgb(94, 195, 93);
//$success: rgb(35, 209, 96);
$success: rgb(70, 201, 58);
//noinspection CssInvalidFunction
$success-invert: findColorInvert($success);

//noinspection SpellCheckingInspection
$success-light: transparentize($success, $alpha);
$success-light-invert: $success;

//$warning: rgb(255, 208, 87);
$warning: rgb(255, 186, 0);
//noinspection CssInvalidFunction
$warning-invert: findColorInvert($warning);

//noinspection SpellCheckingInspection
$warning-light: transparentize($warning, $alpha);
$warning-light-invert: $warning;

//$danger: rgb(255, 64, 64);
$danger: rgb(255, 71, 87);
//noinspection CssInvalidFunction
$danger-invert: findColorInvert($danger);

//noinspection SpellCheckingInspection
$danger-light: transparentize($danger, $alpha);
$danger-light-invert: $danger;

//noinspection CssInvalidPseudoSelector,CssUnknownProperty
:export {
  light: $light;
  lightInvert: $light-invert;
  lightButton: $light-light;
  lightButtonInvert: $light-light-invert;
  info: $info;
  infoInvert: $info-invert;
  infoButton: $info-light;
  infoButtonInvert: $info-light-invert;
  success: $success;
  successInvert: $success-invert;
  successButton: $success-light;
  successButtonInvert: $success-light-invert;
  warning: $warning;
  warningInvert: $warning-invert;
  warningButton: $warning-light;
  warningButtonInvert: $warning-light-invert;
  danger: $danger;
  dangerInvert: $danger-invert;
  dangerButton: $danger-light;
  dangerButtonInvert: $danger-light-invert;
  primary: $primary;
  primaryInvert: $primary-invert;
  primaryButton: $primary-light;
  primaryButtonInvert: $primary-light-invert;
}

