/*
 Туточки мы перезаписываем все основные части
 компонентов buefy. Он же в свою очередь
 тянет зависимости от набора стилей bulma CSS framework.
  https://bulma.io/documentation
*/
/* Переменные bulma */
@import "~bulma/sass/utilities/initial-variables";
@import "~bulma/sass/utilities/functions";
@import "~bulma/sass/utilities/derived-variables";
@import "colors";
@import "fonts";

// UI
//$ui-offset: 3.5625;
$ui-offset: 4.5;

// Toast
$toast-border-radius: .75rem;

$radius: 5px;
$radius-small: $radius;
$radius-large: $radius * 2;

/* Base: Size  */
$size-base: 1rem;
$size-normal: 0.875rem;
$icon-dimensions: 1rem;
$default-padding: $size-base * 1.5;

/* Base: Color */
$base-color: white;
$base-color-light: #f5f8fa;
$base-gradient: linear-gradient(to right, $base-color, #1e344c);

/* Override Primary */
$primary: $primary;

/* Control */
$control-height: 2.5em;

/* Checkbox override */
$checkbox-background-color: #C9CFDB;
$checkbox-border-color: #C9CFDB;
$checkbox-size: 0.938rem;
$checkbox-border-radius: 3px;
//$checkbox-active-background-color: $primary;

/* Switch override */
//$switch-active-background-color: $primary;

/* Body */
$body-background-color: $base-color-light;

/* Navbar: Bulma override */
//noinspection SassScssUnresolvedVariable
$navbar-dropdown-radius: $radius-small;
$navbar-height: $size-base * $ui-offset;

/* Icon: specifics */
$icon-update-mark-size: $size-base * .5;
//noinspection SassScssUnresolvedVariable
$icon-update-mark-color: $yellow;

/* Aside: Bulma override */
//noinspection SassScssUnresolvedVariable
$menu-item-color: $grey;
$menu-item-radius: 0;
$menu-item-hover-color: transparentize($primary, 0.2);
$menu-item-hover-background-color: transparent;
$menu-item-active-color: $primary;
$menu-item-active-background-color: transparent;
$menu-list-link-padding: $size-base;

/* Aside: specifics */
$aside-width-until-widescreen: $size-base * $ui-offset;
$aside-width-widescreen: $size-base * 12;
$aside-logo-width: $size-base * 5;
$aside-mobile-width: $size-base * 15;
$aside-icon-width: $size-base * 3;
$aside-submenu-font-size: $size-base * .95;
$aside-box-shadow: none;
$aside-background-color: #FFFFFF;


/* Card: Bulma override */
$card-shadow: none;
$card-header-shadow: none;
$card-header-weight: 500;
$card-header-padding: 2.5rem 1.5rem 1.5rem 1.5rem;
$card-overflow: visible;


/* Card: specifics */
$card-header-border-bottom-color: $base-color-light;

/* Table: Bulma override */
//noinspection SassScssUnresolvedVariable
$table-cell-border: 1px solid $white-bis;
$table-cell-padding: 0.5rem;
$table-background-color: rgba(255, 255, 255, 0);
$table-body-background-color: #fff;
$table-sticky-header-height: 32rem;


/* Table: specifics */
$table-avatar-size: 1.5rem;
$table-avatar-size-mobile: 25vw;

/* Modal card */
$modal-card-width: 40vw;
$modal-card-width-mobile: 90vw;

/* Notification: Bulma override */
//noinspection SassScssUnresolvedVariable
$notification-radius: $radius-small;
$notification-padding: $default-padding * .75 $default-padding;

/* Progress: Bulma override */
//noinspection SassScssUnresolvedVariable
$progress-bar-background-color: $white-ter;

/* Tabs: Bulma override */
$tabs-link-color: #AEAEAE;
$tabs-link-active-color: #000;
$tabs-link-active-border-bottom-color: $primary;
$tabs-link-hover-border-bottom-color: rgba(255, 255, 255, 0);

/* Pagination: Bulma override */
$pagination-current-background-color: rgba(255, 255, 255, 0);

/* Button:  */
$button-color: $light;

$placeholder-font-weight: 400;
$placeholder-color: #c2c2c2;

:export {
  sizeNormal: $size-normal
}
